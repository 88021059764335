
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

input, select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
} 

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #FFFFFF;
}

img {
  height: 20px;
  width: 140px;
  cursor: pointer;
}

img.main-text {
  height: 153px;
  width: 314px;
  cursor: initial;
}

h1 {
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
}

input::placeholder {
  color: #ABABAB;
}

label {
  margin-bottom: 8px;
}

th.sort {
  cursor: pointer;
}

.col input[type="file"] {
  border: none;
  width: 40%;
  max-width: 250px;
  padding-left: 0px;
  margin-top: 12px;
  height: 25px;
}

.col input[type="date"] {
  width: 90%;
  max-width: 400px;
}

table {
  font-family: 'Open Sans', sans-serif;
  border-collapse: separate;
  border-spacing: 0px 8px;
  padding: 0px 12px;
  border-radius: 4px;
  background-color: #EFEFEF;
  margin-left: 2vw;
  /*margin-right: 1vw;*/
}

thead th {
  position: sticky;
  top: 0px;
  background-color: #EFEFEF;
  height: 44px;
}

thead tr {
  background-color: #EFEFEF;
  margin-bottom: 4px;
  padding-left: 12px;
}

th {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  padding-left: 12px;
}
tr {
  height: 60px;
  background-color: #FFFFFF;
  margin-bottom: 24px;
}
td {
  background-color: transparent;
  margin: 0;
  border: none;
  color: #808080;
  font-weight: 500;
  font-size: 13px;
  padding: 12px;
}

/*td, th {
  min-width: 100px;
}*/

tr td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

tr td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

table thead {
  border-spacing: 0px;
}

h1, h2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

tr:hover .edit {
  visibility: visible;
}

tbody tr {
  cursor: pointer;
}

tbody tr:hover {
  cursor: pointer;
  background-color: #F2656C;
}

tbody tr:hover td {
  color: #FFFFFF;
}

textarea {
  font-family: 'Open Sans', sans-serif;
  border-radius: 4px;
  padding-top: 10px;
}

.vendor-profile input.vendor-input,
.vendor-profile textarea.vendor-input {
  border: none;
  padding-left: 12px;
  margin-bottom: 0;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  pointer-events: none;
  box-sizing: border-box;
}

.vendor-profile select.vendor-input {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.vendor-profile p.vendor-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.vendor-profile textarea.vendor-input {
  pointer-events: all;
}

.vendor-profile .vendor-input.update,
.vendor-profile .vendor-name.update {
  border: 1px solid #E1E1E1;
  pointer-events: all;
}
.edit {
  visibility: hidden;
}

.curve {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/localperkstest.appspot.com/o/CurvedBackground.svg?alt=media&token=96281c0f-debe-416e-b479-468ad2988c62&_gl=1*1162cmo*_ga*MTkxMzE3MzM5Mi4xNjg5MTE2MzM4*_ga_CW55HF8NVT*MTY5NjU0NzQ5MS4xMDQuMS4xNjk2NTQ3NTMxLjIwLjAuMA..');
  color: #FFFFFF;
  background-size: 100vw max(700px, 85vh);
  background-repeat: no-repeat;
  background-position-x: left;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: -1;
  padding-left: 40px;
}

.overlay {
  display: none;
}

.label {
  font-weight: 600;
}

.sub-label {
  margin-top: 0px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
}

.active {
  color: #000000;
}

.inactive {
  color: #ABABAB;
}

.vendor-profile .col .col {
  width: 99%;
}

.vendor-profile .col .col:first-child {
  margin-right: 8px;
}

.vendor-profile .label, .vendor-profile .vendor-input {
  padding-left: 12px;
}

.ml24 {
  margin-left: 24px;
}

.white {
  background-color: #FFFFFF;
  background-image: none;
}

.table {
  /*display: flex;
  flex-direction: column;*/
  align-items: center;
  overflow-y: scroll;
  height: 70vh;
  width: 98vw;
  position: relative;
}

.table.user, .table.receipts {
  display: flex;
  flex-direction: column;
}

.table.receipts table {
  min-width: 400px;
}

.App {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.fa-pencil {
  cursor:pointer;
}

.fa-pencil:hover {
  color: #333333;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.link .row.center {
  display: flex;
  align-items: center;
  justify-content: start;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar.left {
  justify-content: start;
}

.col {
  display: flex;
  flex-direction: column;
}

.col.left {
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.col input, .col select {
  width: 90%;
  max-width: 400px;
  margin-bottom: 24px;
  height: 40px;
  padding-left: 12px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #000000;
}

.col input:active, .col input:focus-visible {
  border: 1px solid #333333;
  outline: none;
}

.topbar {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 52px;
  margin-right: 24px;
  margin-left: 40px;
}

.space {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  max-width: 720px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  margin: 24px 24px;
}

.topbar.space {
  margin-right: 40px;
  max-width: 95vw;
}

.main-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.link {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: #333333;
  margin-right: 24px;
}

.phone {
  min-width: 140px;
}

.address {
  min-width: 240px;
}

.name {
  min-width: 240px;
}

.date {
  min-width: 180px;
}

.link:hover, .profile:hover, .link-red:hover {
  transform: scale(1.1);
}

.link-red {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: #FF969F;
}

.topbar a {
  text-decoration: none !important;
}

.errorMessage {
  color: #FF0000;
}

div.error, input.error {
  border: solid 2px #FF0000;
}

.right {
  justify-content: end;
}

.hide {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.vendor-profile select.vendor-input.update.disabled {
  pointer-events: none;
}

.mt24 {
  margin-top: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.pr24 {
  padding-right: 24px;
}

.mr8 {
  margin-right: 8px;
}

.m24 {
  margin: 0 24px;
}

.row.m24 {
  margin-bottom: 30px;
}

.profile {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.button, button {
  height: 40px;
  border: 1px solid #E1E1E1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 400px;
  justify-content: center;
  margin-bottom: 24px;
  background-color: #E1E1E1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.button:hover, button:hover {
  background-color: #C0C0C0;
}

.form, .logoForm, .lat-lon-form {
  background-color: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  max-width: 500px;
}

.local-perks {
  padding: 0 24px;
  background-color: #E1E1E1;
  width: 100%;
  max-width: 700px;
  border-radius: 4px;
  margin-bottom: 40px;
  margin-top: 24px;
}

.link a {
  text-decoration: none;
}

.local-perks li, .local-perks p {
  text-align: left;
}

.local-perks li {
  margin-bottom: 24px;
}

.local-perks ol {
  padding-inline-start: 16px;
}

.logoForm, .lat-lon-form {
  margin-bottom: 40px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.subtext {
  font-size: 21px;
  line-height: 32px;
  font-weight: 400;
  width: 362px;
  margin-top: 36px;
}

.mr40 {
  margin-right: 40px;
}

.apple-store, .google-store {
  height: 40px;
}

.apple-store {
  width: 120px;
  margin-top: 40px;
}

.google-store {
  width: 135px;
  margin-top: 28px;
}

.screenshot {
  width: 260px;
  height: 523px;
  margin-top: min(137px, 20vh);
  cursor: initial;
}

.align-center {
  display: flex;
  align-items: center;
}

.cutoff {
  overflow: hidden;
}

.bars {
  display: none;
}

.sidebar {
  width: 64vw;
  height: 100vh;
  top: 0;
  right: 0;
  position: absolute;
  background-color: #2A2A2A;
  z-index: 4;
  justify-content: space-between;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  z-index: 12;
}

.sidebar.light {
  background-color: #F2656C;
}

.vendor-profile .end {
  height: auto;
  margin-top: 0;
  align-items: flex-start;
}

.end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  margin-top: 24px;
}

.search {
  position: absolute;
  top: 70px;
  left: 2vw;
}

.search input {
  width: 240px;
  height: 35px;
  border-color: #ABABAB;
  margin-bottom: 0px;
}

.search p {
  font-size: 17px;
  font-weight: 500;
  color: #ABABAB;
  cursor: pointer;
}

.search p.active {
  color: #000000;
}

.search p:hover {
  transform: scale(1.1);
}

.search.row {
  align-items: center;
  display: flex;
  justify-content: center;
}

.search.row p, .search.row input {
  margin-right: 16px;
}

.sidebar i.fa-x {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 400;
  display: flex;
  margin-right: 40px;
  height: 20px;
  margin-bottom: 16px;
}

.sidebar .link {
  height: 48px;
  align-items: center;
  justify-self: start;
}

.sidebar .link p, .sidebar .link i {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

.privacy {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20vw;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 200px;
}

.privacy h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 31.2px;
}

.privacy p {
  color: #374151;
  font-size: 16px;
  font-weight: 500;
  max-width: 1130px;
}

.vendor-profile {
  border: solid 1px #E1E1E1;
  border-radius: 4px;
  width: 90%;
  max-width: 600px;
  min-height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  box-sizing: border-box;
}

.vendor-profile .vendor-name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  border: none;
  text-align: center;
  pointer-events: none;
}

.reset {
  cursor: pointer;
}

.reset:hover {
  transform: scale(1.1);
}

.even {
  display: flex;
  justify-content: center;
  width: 100%;
}

.w50 {
  width: 50vw;
}

.w50p {
  width: 50%;
}

.mr12 {
  margin-right: 12px;
}

.vendor-category {
  font-size: 14px;
  font-weight: 500;
}

.col.center h1 {
  max-width: 50vw;
}

.vendor-profile .label {
  font-size: 13px;
  margin-bottom: 4px;
  font-weight: 600;
  color: #787878;
}

.vendor-profile p {
  margin-top: 0px;
}

.vendor-profile .vendor-select {
  padding-left: 12px;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0;
}

.vendor-profile .vs {
  margin-bottom: 0;
}

i.fas.fa-download {
  cursor: pointer;
}

i.fas.fa-download:hover {
  scale: 1.2;
}

#logo-img {
  height: 88px;
  width: 88px;
  border-radius: 100%;
  border: 1px solid #E1E1E1;
}

#profile {
  border: solid 1px #e1e1e1;
  border-radius: 4px;
  height: 40px;
  width: 200px;
  position: absolute;
  top: 60px;
  right: 24px;
  visibility: hidden;
  cursor: pointer;
}

#profile:hover {
  background-color: #E1E1E1;
}

#info {
  height: 56vh;
  overflow-y: scroll;
  overflow-x: clip;
}

#edit, #save {
  position: absolute;
  top: 24px;
  right: 24px;
  font-weight: 600;
  cursor: pointer;
}

#cancel {
  position: absolute;
  top: 24px;
  right: 72px;
  font-weight: 600;
  cursor: pointer;
}

#edit:hover, #save:hover, #cancel:hover {
  scale: 1.1;
}

#download {
  position: absolute;
  bottom: 12px;
  right: 60px;
  z-index: 10;
}

#tabs {
  position: absolute;
  top: 0;
  left: 24px;
  cursor: pointer;
}

#tabs h2:hover {
  transform: scale(1.1);
}

#rel {
  position: relative;
}

#userTable th i {
  margin-left: 12px;
  cursor: pointer;
}

#userTable th i:hover {
  transform: scale(1.1);
}

#white-logo {
  position: absolute;
  bottom: 40px;
  right: 25%;
}

.table.vendor table {
  width: 98%;
}


@media (max-height: 786px) {
  .screenshot {
    margin-top: 6vh;
  }
}

@media (max-width: 760px) {

  h1 {
    font-size: 40px;
  }

  .menu {
    display: none;
  }

  .subtext {
    width: 172px;
    margin-top: 180px;
  }

  img.main-text {
    position: absolute;
  }

  .screenshot {
    margin-top: 180px;
  }

  .apple-store {
    width: 120px;
    margin-top: 30px;
  }

  .bars {
    display: flex;
    margin-right: 24px;
  }

  .bars i {
    font-size: 24px;
  }

  .overlay {
    display: none;
    height: 100vh;
    width: 100vw;
    opacity: 0.85;
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
  }

  .table {
    display: block;
    overflow: scroll;
    height: 53vh;
    margin-top: 24px;
    position: relative;
  }
  
  #info {
    height: 50vh;
    overflow-y: scroll;
  }

  #title {
    padding-top: 110px;
  }

  .vendor-profile {
    width: 99%;
  }
}